import { createSlice } from '@reduxjs/toolkit'

import { ICode } from '@/common/interfaces/code-name.interface'
import {
  errorItemData,
  errorListData,
  receiveItemData,
  receiveListData,
  requestItemData,
  requestListData,
} from '@/common/utils/state.utils'

import {
  ILease,
  ILeaseAnnounce,
  ILeaseCode,
  ILeaseDamageItem,
  ILeaseDamages,
  ILeaseDeposit,
  ILeaseDocument,
  ILeaseDuring,
  ILeaseFromInterval,
  ILeaseInvoice,
  ILeaseMedia,
  ILeasePatient,
  ILeasePromotionReport,
  ILeasesTypesSite,
  LeaseDayDetails,
} from '@/features/leases/interfaces/leases.interface'

import { leasesService } from './leases.service'
import { LeaseState } from './leases.state.interface'

const initialState = new LeaseState()

const leasesSlice = createSlice({
  name: 'leases',
  initialState,
  reducers: {
    resetLeaseDayState: (state) => {
      state.leaseDay = initialState.leaseDay
    },
  },
  extraReducers: (builder) => {
    builder

      ///leases
      .addCase(leasesService.fetchLeases.pending, (state) => {
        return requestListData<LeaseState, ILease[]>({ ...state }, ['leases'])
      })
      .addCase(leasesService.fetchLeases.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILease[]>(
          { ...state },
          ['leases'],
          action.payload.items,
          action.payload.total_items,
        )
      })
      .addCase(leasesService.fetchLeases.rejected, (state, action) => {
        return errorListData<LeaseState, ILease[]>({ ...state }, ['leases'], String(action.error.message))
      })

      .addCase(leasesService.fetchBookingTypes.pending, (state) => {
        return requestListData<LeaseState, ICode[]>({ ...state }, ['bookingTypes'])
      })
      .addCase(leasesService.fetchBookingTypes.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ICode[]>({ ...state }, ['bookingTypes'], action.payload)
      })
      .addCase(leasesService.fetchBookingTypes.rejected, (state, action) => {
        return errorListData<LeaseState, ICode[]>({ ...state }, ['bookingTypes'], String(action.error.message))
      })

      ///leases simple report
      .addCase(leasesService.fetchLeasesSimpleReport.pending, (state) => {
        return requestListData<LeaseState, ILease[]>({ ...state }, ['leasesSimpleReport'])
      })
      .addCase(leasesService.fetchLeasesSimpleReport.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILease[]>(
          { ...state },
          ['leasesSimpleReport'],
          action.payload.items,
          action.payload.total_items,
        )
      })
      .addCase(leasesService.fetchLeasesSimpleReport.rejected, (state, action) => {
        return errorListData<LeaseState, ILease[]>({ ...state }, ['leasesSimpleReport'], String(action.error.message))
      })

      ///leases detailed report
      .addCase(leasesService.fetchLeasesDetailedReport.pending, (state) => {
        return requestListData<LeaseState, ILease[]>({ ...state }, ['leasesDetailedReport'])
      })
      .addCase(leasesService.fetchLeasesDetailedReport.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILease[]>(
          { ...state },
          ['leasesDetailedReport'],
          action.payload.items,
          action.payload.total_items,
        )
      })
      .addCase(leasesService.fetchLeasesDetailedReport.rejected, (state, action) => {
        return errorListData<LeaseState, ILease[]>({ ...state }, ['leasesDetailedReport'], String(action.error.message))
      })

      ///leases promotion report
      .addCase(leasesService.fetchLeasesPromotionReport.pending, (state) => {
        return requestListData<LeaseState, ILeasePromotionReport[]>({ ...state }, ['leasesPromotionReport'])
      })
      .addCase(leasesService.fetchLeasesPromotionReport.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILeasePromotionReport[]>(
          { ...state },
          ['leasesPromotionReport'],
          action.payload,
        )
      })
      .addCase(leasesService.fetchLeasesPromotionReport.rejected, (state, action) => {
        return errorListData<LeaseState, ILeasePromotionReport[]>(
          { ...state },
          ['leasesPromotionReport'],
          String(action.error.message),
        )
      })

      ///leases announce
      .addCase(leasesService.fetchLeasesAnnounce.pending, (state) => {
        return requestListData<LeaseState, ILeaseAnnounce[]>({ ...state }, ['leasesAnnounce'])
      })
      .addCase(leasesService.fetchLeasesAnnounce.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILeaseAnnounce[]>({ ...state }, ['leasesAnnounce'], action.payload)
      })
      .addCase(leasesService.fetchLeasesAnnounce.rejected, (state, action) => {
        return errorListData<LeaseState, ILeaseAnnounce[]>(
          { ...state },
          ['leasesAnnounce'],
          String(action.error.message),
        )
      })

      /// lease

      .addCase(leasesService.fetchLease.pending, (state) => {
        return requestItemData<LeaseState, ILease>({ ...state }, ['lease'])
      })
      .addCase(leasesService.fetchLease.fulfilled, (state, action) => {
        return receiveItemData<LeaseState, ILease>({ ...state }, ['lease'], action.payload)
      })
      .addCase(leasesService.fetchLease.rejected, (state, action) => {
        return errorItemData<LeaseState, ILease>({ ...state }, ['lease'], String(action.error.message))
      })

      /// lease by interval (calendar)

      .addCase(leasesService.fetchLeasesFromInterval.pending, (state) => {
        return requestItemData<LeaseState, ILeaseFromInterval>({ ...state }, ['leasesInterval'])
      })
      .addCase(leasesService.fetchLeasesFromInterval.fulfilled, (state, action) => {
        const [items, schedule] = action.payload
        return receiveItemData<LeaseState, ILeaseFromInterval>({ ...state }, ['leasesInterval'], {
          items,
          active_schedule: schedule.active_schedule,
        })
      })
      .addCase(leasesService.fetchLeasesFromInterval.rejected, (state, action) => {
        return errorItemData<LeaseState, ILeaseFromInterval>(
          { ...state },
          ['leasesInterval'],
          String(action.error.message),
        )
      })

      /// leases on site (future)

      .addCase(leasesService.fetchSiteRoomsFutureLeases.pending, (state) => {
        return requestItemData<LeaseState, ILeasesTypesSite>({ ...state }, ['leaseTypesSite'])
      })
      .addCase(leasesService.fetchSiteRoomsFutureLeases.fulfilled, (state, action) => {
        return receiveItemData<LeaseState, ILeasesTypesSite>({ ...state }, ['leaseTypesSite'], action.payload)
      })
      .addCase(leasesService.fetchSiteRoomsFutureLeases.rejected, (state, action) => {
        return errorItemData<LeaseState, ILeasesTypesSite>(
          { ...state },
          ['leaseTypesSite'],
          String(action.error.message),
        )
      })

      /// doctors's lease

      .addCase(leasesService.fetchLeaseDoctorsLeases.pending, (state) => {
        return requestListData<LeaseState, ILease[]>({ ...state }, ['doctorsLeases'])
      })
      .addCase(leasesService.fetchLeaseDoctorsLeases.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<LeaseState, ILease[]>({ ...state }, ['doctorsLeases'], items, total_items)
      })
      .addCase(leasesService.fetchLeaseDoctorsLeases.rejected, (state, action) => {
        return errorListData<LeaseState, ILease[]>({ ...state }, ['doctorsLeases'], String(action.error.message))
      })

      /// lease day

      .addCase(leasesService.fetchLeaseDay.pending, (state) => {
        return requestItemData<LeaseState, LeaseDayDetails>({ ...state }, ['leaseDay'])
      })
      .addCase(leasesService.fetchLeaseDay.fulfilled, (state, action) => {
        return receiveItemData<LeaseState, LeaseDayDetails>({ ...state }, ['leaseDay'], action.payload)
      })
      .addCase(leasesService.fetchLeaseDay.rejected, (state, action) => {
        return errorItemData<LeaseState, LeaseDayDetails>({ ...state }, ['leaseDay'], String(action.error.message))
      })

      /// lease codes

      .addCase(leasesService.fetchLeaseCodes.pending, (state) => {
        return requestListData<LeaseState, ILeaseCode[]>({ ...state }, ['leaseCodes'])
      })
      .addCase(leasesService.fetchLeaseCodes.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILeaseCode[]>({ ...state }, ['leaseCodes'], action.payload)
      })
      .addCase(leasesService.fetchLeaseCodes.rejected, (state, action) => {
        return errorListData<LeaseState, ILeaseCode[]>({ ...state }, ['leaseCodes'], String(action.error.message))
      })

      /// lease media

      .addCase(leasesService.fetchLeaseMedia.pending, (state) => {
        return requestListData<LeaseState, ILeaseMedia>({ ...state }, ['leaseMedia'])
      })
      .addCase(leasesService.fetchLeaseMedia.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILeaseMedia>({ ...state }, ['leaseMedia'], action.payload)
      })
      .addCase(leasesService.fetchLeaseMedia.rejected, (state, action) => {
        return errorListData<LeaseState, ILeaseMedia>({ ...state }, ['leaseMedia'], String(action.error.message))
      })

      /// lease deposit

      .addCase(leasesService.fetchLeaseDeposit.pending, (state) => {
        return requestListData<LeaseState, ILeaseDeposit>({ ...state }, ['leaseDeposit'])
      })
      .addCase(leasesService.fetchLeaseDeposit.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILeaseDeposit>({ ...state }, ['leaseDeposit'], action.payload)
      })
      .addCase(leasesService.fetchLeaseDeposit.rejected, (state, action) => {
        return errorListData<LeaseState, ILeaseDeposit>({ ...state }, ['leaseDeposit'], String(action.error.message))
      })

      /// lease damages

      .addCase(leasesService.fetchLeaseDamages.pending, (state) => {
        return requestListData<LeaseState, ILeaseDamages>({ ...state }, ['leaseDamages'])
      })
      .addCase(leasesService.fetchLeaseDamages.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILeaseDamages>({ ...state }, ['leaseDamages'], action.payload)
      })
      .addCase(leasesService.fetchLeaseDamages.rejected, (state, action) => {
        return errorListData<LeaseState, ILeaseDamages>({ ...state }, ['leaseDamages'], String(action.error.message))
      })
      .addCase(leasesService.addDamage.pending, (state) => {
        return requestListData<LeaseState, ILeaseDamages>({ ...state }, ['leaseDamage'])
      })
      .addCase(leasesService.addDamage.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILeaseDamages>({ ...state }, ['leaseDamage'], action.payload)
      })
      .addCase(leasesService.addDamage.rejected, (state, action) => {
        return errorListData<LeaseState, ILeaseDamages>({ ...state }, ['leaseDamage'], String(action.error.message))
      })
      .addCase(leasesService.processRefund.pending, (state) => {
        return requestListData<LeaseState, ILeaseDamages>({ ...state }, ['leaseDamage'])
      })
      .addCase(leasesService.processRefund.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILeaseDamages>({ ...state }, ['leaseDamage'], action.payload)
      })
      .addCase(leasesService.processRefund.rejected, (state, action) => {
        return errorListData<LeaseState, ILeaseDamages>({ ...state }, ['leaseDamage'], String(action.error.message))
      })

      /// lease damages list

      .addCase(leasesService.fetchLeaseDamageList.pending, (state) => {
        return requestListData<LeaseState, ILeaseDamageItem[]>({ ...state }, ['leaseDamagesList'])
      })
      .addCase(leasesService.fetchLeaseDamageList.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<LeaseState, ILeaseDamageItem[]>({ ...state }, ['leaseDamagesList'], items, total_items)
      })
      .addCase(leasesService.fetchLeaseDamageList.rejected, (state, action) => {
        return errorListData<LeaseState, ILeaseDamageItem[]>(
          { ...state },
          ['leaseDamagesList'],
          String(action.error.message),
        )
      })

      /// during the lease

      .addCase(leasesService.fetchDuringLease.pending, (state) => {
        return requestListData<LeaseState, ILeaseDuring[]>({ ...state }, ['leaseDuring'])
      })
      .addCase(leasesService.fetchDuringLease.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<LeaseState, ILeaseDuring[]>({ ...state }, ['leaseDuring'], items, total_items)
      })
      .addCase(leasesService.fetchDuringLease.rejected, (state, action) => {
        return errorListData<LeaseState, ILeaseDuring[]>({ ...state }, ['leaseDuring'], String(action.error.message))
      })

      /// lease documents

      .addCase(leasesService.fetchLeaseDocuments.pending, (state) => {
        return requestListData<LeaseState, ILeaseDocument[]>({ ...state }, ['leaseDocuments'])
      })
      .addCase(leasesService.fetchLeaseDocuments.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILeaseDocument[]>({ ...state }, ['leaseDocuments'], action.payload)
      })
      .addCase(leasesService.fetchLeaseDocuments.rejected, (state, action) => {
        return errorListData<LeaseState, ILeaseDocument[]>(
          { ...state },
          ['leaseDocuments'],
          String(action.error.message),
        )
      })

      /// lease invoices

      .addCase(leasesService.fetchLeaseInvoices.pending, (state) => {
        return requestListData<LeaseState, ILeaseInvoice[]>({ ...state }, ['leaseInvoices'])
      })
      .addCase(leasesService.fetchLeaseInvoices.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILeaseInvoice[]>({ ...state }, ['leaseInvoices'], action.payload.items)
      })
      .addCase(leasesService.fetchLeaseInvoices.rejected, (state, action) => {
        return errorListData<LeaseState, ILeaseInvoice[]>({ ...state }, ['leaseInvoices'], String(action.error.message))
      })

      ///lease patients
      .addCase(leasesService.fetchLeasePatients.pending, (state) => {
        return requestListData<LeaseState, ILeasePatient[]>({ ...state }, ['leasePatients'])
      })
      .addCase(leasesService.fetchLeasePatients.fulfilled, (state, action) => {
        return receiveListData<LeaseState, ILeasePatient[]>(
          { ...state },
          ['leasePatients'],
          action.payload.items,
          action.payload.total_items,
        )
      })
      .addCase(leasesService.fetchLeasePatients.rejected, (state, action) => {
        return errorListData<LeaseState, ILeasePatient[]>({ ...state }, ['leasePatients'], String(action.error.message))
      })
  },
})

export const { resetLeaseDayState } = leasesSlice.actions

export default leasesSlice.reducer
